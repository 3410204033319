/* eslint-disable react-hooks/exhaustive-deps */
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SortIcon from '@mui/icons-material/Sort';
import {
  Box,
  Button,
  CircularProgress,
  Divider as MuiDivider,
  Link,
  MenuItem,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import {
  AgentView,
  ApplicationStatusGroups,
  AsyncState,
  AuthUserClaims,
  Dispositions,
  getColor,
  getDispositions,
  GroupRole,
  SearchCriteria,
  useAgentsFromGroup,
  UserRoles,
  Workflows,
} from '@ozark/common';
import {CancelOperationMessage} from '@ozark/common/api/Constants';
import {
  ActiveFilter,
  Card,
  forceActiveFilter,
  InfiniteEntities,
  InputSearch,
  StyledDivider,
  Title,
} from '@ozark/common/components';
import {
  ApplicationFilters,
  ApplicationListData,
} from '@ozark/crm/src/components/Applications/Types';
import {ApplicationResponse} from '@ozark/functions/src/functions/express/private/types';
import {CancelTokenSource} from 'axios';
import {memo, ReactNode, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router';
import * as ROUTES from '../../constants/routes';
import {useStore} from '../../store/helpers';
import {useStyles} from './styles';

const DEFAULT_VALUE = '0';

const defaultFilters = {
  showAll: true,
  statusGroup: ApplicationStatusGroups.open,
  disposition: null,
  selectedAgent: null,
};

const defaultPaging: SearchCriteria = {
  order: 'desc',
  // especially for agent support queue we use pre-defined applications order by (see service)
  orderBy: 'createdAt',
  limit: 50, // page size
  offset: 1, // page
};

const viewableDispositions = Workflows[UserRoles.agentSupport].viewable;

const getDispositionFilterValues = (
  disposition: Dispositions | null,
  group: ApplicationStatusGroups
) => {
  if (disposition) {
    return [disposition];
  }

  return getDispositions(group, viewableDispositions);
};

const addShowAllFilter = (target: ActiveFilter[], showAll: boolean, uid: string): void => {
  if (showAll) return;
  target.push(forceActiveFilter(ApplicationFilters, 'agentId', '__eq', uid));
};

const addGroupIdFilter = (target: ActiveFilter[], groupId: string | null): void => {
  if (groupId == null || groupId === DEFAULT_VALUE) return;
  target.push(forceActiveFilter(ApplicationFilters, 'groupId', '__eq', groupId));
};

const addAgentFilter = (target: ActiveFilter[], agentId: string | null): void => {
  if (agentId == null || agentId === DEFAULT_VALUE) return;
  target.push(forceActiveFilter(ApplicationFilters, 'agentId', '__eq', agentId));
};

const addDispositionsFilter = (
  target: ActiveFilter[],
  disposition: Dispositions | null,
  dispositionGroup: ApplicationStatusGroups
): void => {
  const dispositionFilterValues = getDispositionFilterValues(disposition, dispositionGroup);

  if (!dispositionFilterValues) {
    return;
  }

  target.push(
    forceActiveFilter(ApplicationFilters, 'dispositions', '__eq', dispositionFilterValues.join(','))
  );
};

const addAssociatedAgentsFilter = (target: ActiveFilter[], uid: string | null): void => {
  if (uid == null || uid === DEFAULT_VALUE) {
    return;
  }

  target.push(forceActiveFilter(ApplicationFilters, 'associatedAgents', '__eq', uid));
};

const buildFilters = (input: {
  claims: AuthUserClaims | null | undefined;
  uid: string;
  showAll: boolean;
  statusGroup: ApplicationStatusGroups;
  disposition: Dispositions | null;
  agentId: string | null;
}): ActiveFilter[] => {
  const result: ActiveFilter[] = [];

  if (!input.showAll) {
    addShowAllFilter(result, input.showAll, input.uid);
  } else if (input.agentId) {
    addAgentFilter(result, input.agentId);
  } else {
    if (input.claims?.groupRole === GroupRole.administrator) {
      addGroupIdFilter(result, input.claims.groupId ?? null);
    } else if (input.claims?.groupRole === GroupRole.member) {
      addAssociatedAgentsFilter(result, input.uid);
    }
  }

  addDispositionsFilter(result, input.disposition, input.statusGroup);
  result.push(forceActiveFilter(ApplicationFilters, 'deleted', '__eq', 'false'));

  return result;
};

const AgentSupport = () => {
  const classes = useStyles();
  const history = useHistory();
  const {authProfile, claims, apiClient} = useStore();
  const {
    documents: {data: agents},
  } = useAgentsFromGroup(authProfile.data?.id, claims?.groupId, claims?.groupRole);
  const initialized = useRef(false);
  const [showAll, setShowAll] = useState(defaultFilters.showAll);
  const [statusGroup, setStatusGroup] = useState(defaultFilters.statusGroup);
  const [disposition, setDisposition] = useState<Dispositions | null>(defaultFilters.disposition);
  const [selectedAgent, setSelectedAgent] = useState<AgentView | null>(
    defaultFilters.selectedAgent
  );
  const [searchQuery, setSearchQuery] = useState('');
  const [searchCriteria, setSearchCriteria] = useState(defaultPaging);

  const [isLoadingFirstPage, setIsLoadingFirstPage] = useState(false);
  const [, setCancelTokenSource] = useState<CancelTokenSource | undefined>();

  const [applications, setApplications] = useState<AsyncState<ApplicationListData>>({
    promised: true,
  });

  useEffect(() => {
    // This is to prevent the initial load from firing twice in the strict mode.
    if (!initialized.current) {
      initialized.current = true;
      loadData(searchCriteria);
    }
  }, []);

  useEffect(() => {
    const newPaging = {...searchCriteria, offset: 1};
    setSearchCriteria(newPaging);
    loadData(newPaging);
  }, [showAll, statusGroup, disposition, claims, selectedAgent?.id, searchQuery]);

  const loadData = (paging: SearchCriteria, onLoaded?: () => void) => {
    if (paging.offset === 1) {
      setIsLoadingFirstPage(true);
    }

    const cancelSource = apiClient?.applications.getCancelTokenSource();
    setCancelTokenSource(prev => {
      //Check if there are any previous pending requests
      if (prev !== undefined) {
        prev.cancel(CancelOperationMessage);
      }
      return cancelSource;
    });

    const filters = buildFilters({
      claims,
      uid: authProfile.data!.id,
      showAll,
      statusGroup,
      disposition,
      agentId: selectedAgent?.id ?? null,
    });
    apiClient.applications
      .getAgentSupportApplications(paging, searchQuery, filters, cancelSource?.token)
      .then(result => {
        if (result == null) {
          setApplications({promised: false, data: {items: [], hasNextPage: false}});
          return;
        }

        const listData: ApplicationListData = {
          items:
            result.offset === 0
              ? result.data
              : [...(applications.data?.items ?? []), ...result.data],
          hasNextPage: result.data.length === result.limit || false,
          totalCount: result.totalCount,
        };
        setApplications({promised: false, data: listData});
        setIsLoadingFirstPage(false);
      })
      .catch((err: any) => {
        if (err?.message === CancelOperationMessage) {
          return;
        }
        console.error(err);
        setApplications({promised: false, error: err || {}});
        setIsLoadingFirstPage(false);
      })
      .finally(() => {
        onLoaded?.();
      });
  };

  const toggleOrder = () => {
    const newValue = {...searchCriteria, order: searchCriteria.order === 'asc' ? 'desc' : 'asc'};
    setSearchCriteria(newValue);
    loadData(newValue);
  };

  const toggleShowAll = () => {
    setSelectedAgent(null);
    setShowAll(!showAll);
  };

  const handleStatusGroupChange = (event: any) => {
    setStatusGroup(event.target.value);
    setDisposition(null);
  };

  const handleDispositionChange = (event: any) =>
    setDisposition(event.target.value === 'null' ? null : event.target.value);

  const handleAgentChange = (event: any) => {
    const agent = agents?.find(agent => agent.id === event.target.value) ?? null;
    setSelectedAgent(agent);
    setShowAll(true);
  };

  const loadNextPage = (onLoaded?: () => void): void => {
    const newValue = {...searchCriteria, offset: searchCriteria.offset + 1};
    setSearchCriteria(newValue);
    loadData(newValue, onLoaded);
  };

  return (
    <div className={classes.root}>
      <Box sx={{display: 'flex'}}>
        <Title
          sx={{width: 'auto'}}
          noBorder
          breadcrumbs={[
            <Link
              component="button"
              variant="body1"
              onClick={() => history.push(ROUTES.APPLICATIONS_AS)}
            >
              Agent Support
            </Link>,
          ]}
        />
        <div className={classes.grow}>
          <Box
            sx={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(158px, min(20%, 220px)))',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            {!!agents?.length && (
              <PanelItem>
                <TextField
                  value={String(selectedAgent ? selectedAgent.id : null)}
                  onChange={handleAgentChange}
                  variant="standard"
                  label="Filter by Agent"
                  sx={{width: '100%'}}
                  InputProps={{
                    classes: {
                      input: classes.selectInput,
                    },
                    disableUnderline: true,
                  }}
                  select
                >
                  <MenuItem value={String(null)}>All Agents</MenuItem>
                  {agents.sortAndMap(
                    agent => (
                      <MenuItem key={agent.id} value={agent.id}>
                        {agent.firstName} {agent.lastName}
                      </MenuItem>
                    ),
                    agent => `${agent.firstName} ${agent.lastName}`
                  )}
                </TextField>
              </PanelItem>
            )}
            <PanelItem dividerPosition={!!agents?.length ? 'start' : undefined}>
              <TextField
                value={statusGroup}
                onChange={handleStatusGroupChange}
                label="Filter by Status"
                sx={{width: '100%'}}
                variant="standard"
                InputProps={{
                  classes: {
                    input: classes.selectInput,
                  },
                  disableUnderline: true,
                }}
                select
              >
                {Object.values(ApplicationStatusGroups)
                  .filter(e => getDispositions(e, viewableDispositions).length > 0)
                  .sortAndMap(e => (
                    <MenuItem key={e} value={e}>
                      {e}
                    </MenuItem>
                  ))}
              </TextField>
            </PanelItem>
            <PanelItem dividerPosition="start">
              <TextField
                value={String(disposition)}
                onChange={handleDispositionChange}
                label="Filter by Disposition"
                sx={{width: '100%'}}
                variant="standard"
                InputProps={{
                  classes: {
                    input: classes.selectInput,
                  },
                  disableUnderline: true,
                }}
                select
              >
                <MenuItem value={String(null)}>
                  {statusGroup === ApplicationStatusGroups.all
                    ? 'All Dispositions'
                    : `All ${statusGroup} Dispositions`}
                </MenuItem>
                {getDispositions(statusGroup, viewableDispositions).sortAndMap(e => (
                  <MenuItem key={e} value={e}>
                    <span style={{color: getColor(e)}}>&bull;&bull;&bull;&nbsp;&nbsp;</span>
                    {e}
                  </MenuItem>
                ))}
              </TextField>
            </PanelItem>
          </Box>

          <Box
            sx={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(158px, min(20%, 220px)))',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <PanelItem sx={{gridColumn: 'span 2'}}>
              <InputSearch
                fieldName="searchApplications"
                placeholder="Search..."
                onSearchChange={setSearchQuery}
                fullWidth
              />
            </PanelItem>
            {!!agents?.length && (
              <PanelItem dividerPosition="start">
                <Button
                  size="small"
                  onClick={toggleShowAll}
                  startIcon={
                    !showAll ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlankIcon />
                  }
                >
                  Assigned to me
                </Button>
              </PanelItem>
            )}
            <PanelItem dividerPosition="start">
              <Button
                size="small"
                onClick={toggleOrder}
                endIcon={
                  searchCriteria.order === 'desc' ? (
                    <SortIcon style={{transform: 'rotateX(180deg)'}} />
                  ) : (
                    <SortIcon />
                  )
                }
              >
                {searchCriteria.order === 'desc' ? 'Newest at Top' : 'Oldest at Top'}
              </Button>
            </PanelItem>
          </Box>
        </div>
      </Box>
      <MuiDivider sx={{margin: '5px 0'}} />

      {isLoadingFirstPage || authProfile.promised || applications.promised ? (
        <Box sx={{position: 'relative', top: '40%', m: '0 auto'}}>
          <CircularProgress color="primary" />
        </Box>
      ) : !applications.data?.items?.length ? (
        <Typography sx={{top: '40%', position: 'relative', textAlign: 'center'}}>
          No Applications
        </Typography>
      ) : (
        <InfiniteEntities
          showTotal
          data={applications.data}
          itemSize={270}
          loadNextPage={loadNextPage}
          onRender={(application: ApplicationResponse) => (
            <Card
              isPortal
              application={application}
              customizeTransferable
              onClick={() =>
                history.push(ROUTES.APPLICATION.replace(':id', application.id), {
                  referrer: 'Agent Support',
                })
              }
            />
          )}
        />
      )}
    </div>
  );
};

const Divider = memo(() => <StyledDivider orientation="vertical" flexItem />);

const PanelItem = ({
  children,
  dividerPosition,
  sx,
}: {
  children?: ReactNode;
  dividerPosition?: 'start' | 'end';
  sx?: SxProps<Theme>;
}) => {
  return (
    <Box sx={{display: 'flex', ...sx}}>
      {dividerPosition === 'start' && <Divider />}
      {children}
      {dividerPosition === 'end' && <Divider />}
    </Box>
  );
};

export default AgentSupport;
