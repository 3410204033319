import {useEffect, useState} from 'react';
import {Collections, Firebase, TicketHistoryRecord} from '../../..';
import {useTicketId} from './useTicketId';

export function useTicketHistory() {
  const {ticketId} = useTicketId();
  const [records, setRecords] = useState<TicketHistoryRecord[]>([]);

  useEffect(() => {
    if (ticketId) {
      return Firebase.firestore
        .collection(Collections.tickets)
        .doc(ticketId)
        .collection(Collections.history)
        .orderBy('createdAt', 'asc')
        .onSnapshot(
          snap => {
            if (snap.size) {
              const result = snap.docs.map(d => d.data() as TicketHistoryRecord);
              setRecords(result);
            } else {
              setRecords([]);
            }
          },
          err => {
            console.error('Error reading attachments: ', err);
            setRecords([]);
          }
        );
    }
  }, [ticketId]);

  return {
    records,
  };
}
