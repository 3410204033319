import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BuildIcon from '@mui/icons-material/Build';
import BusinessIcon from '@mui/icons-material/Business';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import GroupIcon from '@mui/icons-material/Group';
import ReceiptIcon from '@mui/icons-material/Receipt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {Button, Grid, Popover} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  ApplicationEquipment,
  ApplicationView,
  EncryptedField,
  formatDateWithoutTimezone,
  PCIFee,
  PCIFeeValueToString,
  SensitiveFieldCaptions,
  useSensitiveData,
} from '@ozark/common';
import {Line, SummaryBox, SummaryBoxPricing, ViewingSensitiveData} from '@ozark/common/components';
import {
  BusinessInfoWebSiteItemName,
  getWebsiteItemForBusinessInfoSummaryColumns,
} from '@ozark/common/helpers';
import React, {useCallback, useState} from 'react';
import {Link} from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import {Bar} from './Elements/Bar';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    flexDirection: 'row',
    width: '100%',
    display: 'flex',
    padding: '8px',
    alignContent: 'stretch',
    '& div': {
      flexGrow: 1,
    },
  },
  secretTextField: {
    margin: theme.spacing(2, 0, 3),
  },
  feeColumn: {
    paddingLeft: theme.spacing(2),
  },
  maskedValue: {
    color: theme.palette.primary.main,
    display: 'flex',
  },
  visibilityIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const formatCurrency = (value: number | null | '' | string) => {
  if (!value) return '$0';

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(value as number);
};

export const Overview = ({application}: {application: ApplicationView}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [selectedEquipment, setSelectedEquipment] = useState<ApplicationEquipment | null>(null);
  const [decryptedText, setDecryptedText] = useState<string | null>(null);
  const [modalLabel, setModalLabel] = useState<string | null>(null);
  const {decryptField} = useSensitiveData(application.id);

  const onViewSensitiveDataClose = useCallback(() => {
    setDecryptedText(null);
    setModalLabel(null);
  }, []);

  const handleDecryptClick = useCallback(
    async (
      encryptedField: EncryptedField,
      humanFieldName: string,
      modalLabel: string,
      returnEncryptedValue: boolean
    ) => {
      const plainText = await decryptField(encryptedField, humanFieldName, returnEncryptedValue);
      if (!plainText) {
        return;
      }
      setModalLabel(modalLabel);
      setDecryptedText(plainText);
    },
    [decryptField]
  );

  const handleDecryptSSNClick = useCallback(async () => {
    await handleDecryptClick(
      EncryptedField.socialSecurityNumber,
      'SSN',
      SensitiveFieldCaptions.socialSecurityNumber,
      false
    );
  }, [handleDecryptClick]);

  const handleEquipmentDetailClick =
    (equipment: ApplicationEquipment) => (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectedEquipment(equipment);
      setAnchorEl(event.currentTarget);
    };

  const handleTaxIdClick = useCallback(async () => {
    await handleDecryptClick(
      EncryptedField.federalTaxId,
      'Tax Id',
      SensitiveFieldCaptions.viewTaxId,
      true
    );
  }, [handleDecryptClick]);

  const handleBankAccountNumberClick = useCallback(async () => {
    await handleDecryptClick(
      EncryptedField.bankAccountNumber,
      'Bank Account Number',
      SensitiveFieldCaptions.bankAccountNumber,
      true
    );
  }, [handleDecryptClick]);

  const handleRoutingNumberClick = useCallback(async () => {
    await handleDecryptClick(
      EncryptedField.routingNumber,
      'Routing Number',
      SensitiveFieldCaptions.routingNumber,
      true
    );
  }, [handleDecryptClick]);

  const handleCloseMenu = () => {
    setSelectedEquipment(null);
    setAnchorEl(null);
  };

  const getIdentificationColumns = () => {
    let columns = {
      'Application ID': application.distinguishableId,
    } as Line;

    if (application.referenceApp) {
      columns['Reference Application ID'] = (
        <Link to={`${ROUTES.APPLICATIONS}/${application.referenceApp!.appId}`}>
          {application.referenceApp.distinguishableId}
        </Link>
      );
    }

    columns['Internal ID'] = application.id;
    columns['Merchant ID'] = application.mid || 'Not Boarded';

    return [columns] as [Line];
  };

  const getBusinessInfoSummaryColumns = useCallback(() => {
    const items: Line = {
      'Legal Business Name': application.legalBusinessName,
      'Legal Business Address': application.businessAddress1 ? (
        <>
          {application.businessAddress1}
          {application.businessAddress2 || ''}
          <br />
          {application.businessCity}, {application.businessState} {application.businessZip}
        </>
      ) : (
        '-'
      ),
      DBA: application.doingBusinessAs,
      'DBA Address': application.businessAddress1 ? (
        <>
          {application.businessAddress1}
          {application.businessAddress2 || ''}
          <br />
          {application.businessCity}, {application.businessState} {application.businessZip}
        </>
      ) : (
        '-'
      ),
      'Business Phone': (
        <a href={`tel:${application.businessPhone}`}>
          {application.businessPhone || '(No Phone Provided)'}
        </a>
      ),
      'Ownership Type': application.ownershipType,
      'Tax ID': {
        value: !application.federalTaxId ? (
          '-'
        ) : (
          <span className={classes.maskedValue}>
            *****{application.federalTaxId.substring(6, 10)}{' '}
            <VisibilityIcon color="primary" className={classes.visibilityIcon} />
          </span>
        ),
        onClick: handleTaxIdClick,
      },
      'Bank Account #': {
        value: !application.bankAccountNumber ? (
          '-'
        ) : (
          <span className={classes.maskedValue}>
            *****
            {application.bankAccountNumber.substring(
              application.bankAccountNumber.length - 4,
              application.bankAccountNumber.length
            )}{' '}
            <VisibilityIcon color="primary" className={classes.visibilityIcon} />
          </span>
        ),
        onClick: handleBankAccountNumberClick,
      },
      'Bank Routing #': {
        value: !application.routingNumber ? (
          '-'
        ) : (
          <span className={classes.maskedValue}>
            *****
            {application.routingNumber.substring(
              application.routingNumber.length - 4,
              application.routingNumber.length
            )}{' '}
            <VisibilityIcon color="primary" className={classes.visibilityIcon} />
          </span>
        ),
        onClick: handleRoutingNumberClick,
      },
    };
    const element = getWebsiteItemForBusinessInfoSummaryColumns(application);
    if (element) {
      items[BusinessInfoWebSiteItemName] = {
        value: element,
      };
    }
    const result: [Line] | [Line, Line] = [items];
    return result;
  }, [
    application,
    classes.maskedValue,
    classes.visibilityIcon,
    handleBankAccountNumberClick,
    handleRoutingNumberClick,
    handleTaxIdClick,
  ]);

  return (
    <Grid container spacing={2} direction="row" alignItems="stretch">
      <Grid item xs={12}>
        <Bar application={application} />
      </Grid>
      <div className={classes.root}>
        <Grid item xs={9}>
          <Grid container spacing={2} direction="row" alignItems="stretch">
            <Grid item xs={4}>
              <SummaryBox
                groups={[
                  {
                    icon: <GroupIcon />,
                    title: 'Agent Info',
                    columns: [
                      {
                        Agent: application.agent
                          ? `${application.agent.firstName} ${application.agent.lastName}`
                          : 'No Assigned Agent',
                      },
                    ],
                  },
                  {
                    icon: <AccountCircleIcon />,
                    title: 'Identification',
                    columns: getIdentificationColumns(),
                  },
                ]}
              />
            </Grid>
            <Grid item xs={4}>
              <SummaryBox
                groups={[
                  {
                    icon: <BusinessIcon />,
                    title: 'Business Info',
                    columns: getBusinessInfoSummaryColumns(),
                  },
                ]}
              />
            </Grid>
            <Grid item xs={4}>
              <SummaryBox
                groups={[
                  {
                    icon: <ContactPhoneIcon />,
                    title: 'Signer Info',
                    columns: [
                      {
                        'Owner Name': `${application.firstName || ''} ${
                          application.lastName || ''
                        }`,
                        'Email Address': (
                          <a href={`mailto:${application.customerServiceEmail}`}>
                            {application.customerServiceEmail}
                          </a>
                        ),
                        'Phone Number': (
                          <a href={`tel:${application.businessPhone}`}>
                            {application.businessPhone || '(No Phone Provided)'}
                          </a>
                        ),
                        'Home Address': application.homeAddress1 ? (
                          <>
                            {application.homeAddress1}
                            {application.homeAddress2 || ''}
                            <br />
                            {application.homeCity}, {application.homeState} {application.homeZip}
                          </>
                        ) : (
                          '-'
                        ),
                        'Date of Birth': application.dateOfBirth
                          ? formatDateWithoutTimezone(application.dateOfBirth.toDate())
                          : '-',
                        'Social Security #': {
                          value: (
                            <span className={classes.maskedValue}>
                              *****{application.socialSecurityNumberLast4}
                              <VisibilityIcon color="primary" className={classes.visibilityIcon} />
                            </span>
                          ),
                          onClick: handleDecryptSSNClick,
                        },
                      },
                    ],
                  },
                ]}
              />
            </Grid>
            <Grid item xs={4}>
              <SummaryBox
                groups={[
                  {
                    icon: <CreditCardIcon />,
                    title: 'Processing Info',
                    columns: [
                      {
                        'Processing Type': application.processingType,
                        'Business Type': application.businessType,
                        MCC: application.mcc ?? '-',
                        'V/MC/DISC Monthly Sales': application.estimatedMonthlyCreditCardSales
                          ? formatter.format(application.estimatedMonthlyCreditCardSales)
                          : '-',
                        'AMEX Monthly Sales': application.estimatedMonthlyCreditCardSalesAmex
                          ? formatter.format(application.estimatedMonthlyCreditCardSalesAmex)
                          : '-',
                        'Average Ticket': application.estimatedAverageSale
                          ? formatter.format(application.estimatedAverageSale)
                          : '-',
                        'High Ticket': application.estimatedHighestSale
                          ? formatter.format(application.estimatedHighestSale)
                          : '-',
                      },
                    ],
                  },
                ]}
              />
            </Grid>

            <Grid item xs={4}>
              <SummaryBox
                groups={[
                  {
                    icon: <BuildIcon />,
                    title: 'Equipment',
                    columns: [
                      [
                        ...(application.equipment?.id ? [application.equipment] : []),
                        ...(application.equipmentAdditional || []),
                      ].reduce((previous: any, e, i) => {
                        const name = e?.isVarSheet ? e.softwareName : e?.name;
                        if (!e || !name) return previous;
                        previous[`Equipment Type #${i + 1}`] = {
                          value: name,
                          secondaryAction: (
                            <>
                              <Button
                                variant="outlined"
                                size="small"
                                onClick={handleEquipmentDetailClick(e)}
                              >
                                See Details
                              </Button>
                              {e?.VARSheetAttachmentUrl && (
                                <Button variant="outlined" size="small" sx={{marginLeft: 1}}>
                                  <a
                                    href={e?.VARSheetAttachmentUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Download VAR Sheet
                                  </a>
                                </Button>
                              )}
                            </>
                          ),
                        };
                        return previous;
                      }, {}),
                    ],
                  },
                ]}
              />
            </Grid>

            <Grid item xs={4}>
              <SummaryBox
                groups={[
                  {
                    icon: <ReceiptIcon />,
                    title: 'Monthly Fees',
                    columns: [
                      {
                        'Account on File': formatCurrency(application.rateSet?.accountOnFileFee),
                        'Retrieval Fee': formatCurrency(application.rateSet?.retrievalFee),
                        'AVS Fee': formatCurrency(application.rateSet?.avsFee),
                        'Debit Access Fee': formatCurrency(application.rateSet?.debitAccessFee),
                        'PCI Fee': application.rateSet?.pciFee
                          ? PCIFeeValueToString(
                              application.rateSet?.pciFee as PCIFee,
                              application.rateSet?.pciFeeValue
                            )
                          : '-',
                        'Annual Fee': formatCurrency(application.rateSet?.annualFee),
                      },
                      {
                        'Chargeback Fee': formatCurrency(application.rateSet?.chargebackFee),
                        'Monthly Minimum Fee': formatCurrency(
                          application.rateSet?.monthlyMinimumFee
                        ),
                        'Batch Fee': formatCurrency(application.rateSet?.batchFee),
                        "Add'l Service Fee": formatCurrency(
                          application.rateSet?.additionalServicesFee
                        ),
                        'Early Deconversion Fee': formatCurrency(
                          application.rateSet?.earlyDeconversionFee
                        ),
                        'Regulatory Fee': formatCurrency(application.rateSet?.regulatoryFee),
                      },
                    ],
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.feeColumn}>
          <SummaryBoxPricing application={application} />
        </Grid>
      </div>
      {anchorEl && selectedEquipment && (
        <Popover
          open={true}
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <SummaryBox
            groups={[
              {
                icon: <BuildIcon />,
                title: 'Equipment Details',
                columns: [
                  {
                    'Equipment Type': selectedEquipment.name,
                    'Placement Type': selectedEquipment.placementType,
                    'TID #': selectedEquipment.tid || '-',
                    'Shipping Address': application.shipping?.address1 ? (
                      <>
                        {application.homeAddress1}
                        {application.homeAddress2 || ''}
                        <br />
                        {application.homeCity}, {application.homeState} {application.homeZip}
                      </>
                    ) : (
                      '-'
                    ),
                    'Equipment Tracking #': selectedEquipment.sendTrackingNumber ? (
                      <a
                        href={`http://fedex.com/fedextrack/?tracknumbers=${selectedEquipment.sendTrackingNumber}&cntry_code=us`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {selectedEquipment.sendTrackingNumber}
                      </a>
                    ) : (
                      '-'
                    ),
                    'Return Tracking #': selectedEquipment.receiveTrackingNumber ? (
                      <a
                        href={`http://fedex.com/fedextrack/?tracknumbers=${selectedEquipment.receiveTrackingNumber}&cntry_code=us`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {selectedEquipment.receiveTrackingNumber}
                      </a>
                    ) : (
                      '-'
                    ),
                  },
                ],
              },
            ]}
          />
        </Popover>
      )}
      {decryptedText && modalLabel && (
        <ViewingSensitiveData
          decryptedText={decryptedText}
          showSecurityIcon
          label={modalLabel}
          onClose={onViewSensitiveDataClose}
        />
      )}
    </Grid>
  );
};
