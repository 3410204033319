import {AssigneeType, AssociationType, TicketAssignee, TicketAssociation} from '../../documents';

export function getAssigneeName(assignee?: TicketAssignee, isErpUserView: boolean = true) {
  switch (assignee?.type) {
    case AssigneeType.erpDepartment:
      return assignee.erpDepartment;
    case AssigneeType.erpUser:
      return isErpUserView
        ? assignee.erpUser?.name
        : assignee.erpDepartment || assignee.erpUser?.department;
    case AssigneeType.agent:
      return assignee.agent?.name;
    case AssigneeType.merchant:
      return assignee.merchant?.dba;
    default:
      return;
  }
}

export function getAssigneeUid(assignee?: TicketAssignee) {
  switch (assignee?.type) {
    case AssigneeType.erpDepartment:
      return assignee.erpDepartment;
    case AssigneeType.erpUser:
      return assignee.erpUser?.id;
    case AssigneeType.agent:
      return assignee.agent?.id;
    case AssigneeType.merchant:
      return assignee.merchant?.id;
    default:
      return;
  }
}

export function getAssociationName(association?: TicketAssociation, isErpUserView: boolean = true) {
  if (!association) return {name: 'n/a'};
  let name: string | undefined = '...';
  let description = '';

  switch (association.type) {
    case AssociationType.erpUser:
      name = isErpUserView ? association.erpUser?.name : association.erpUser?.department;
      description = 'ERP User';
      break;
    case AssociationType.agent:
      name = association.agent?.name;
      description = 'Agent';
      break;
    case AssociationType.merchant:
      name = association.merchant?.dba;
      description = 'Merchant';
      break;
    case AssociationType.mid:
      name = association.mid?.mid;
      description = 'Merchant ID';
      break;
    case AssociationType.application:
      name = association.application?.name
        ? `${association.application?.name} (${association.application?.distinguishableId})`
        : association.application?.distinguishableId;
      description = 'Application';
      break;
    default:
      console.error('invalid association type', association.type);
      break;
  }

  return {
    name,
    description,
  };
}
