import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {IconButton, Typography, useMediaQuery, useTheme} from '@mui/material';
import {GroupEdit, Loading, Title} from '@ozark/common/components';
import {useHistory} from 'react-router';
import {useStore} from '../../store/helpers';

const Group = () => {
  const {authProfile, group, isUserAdmin} = useStore();
  const theme = useTheme();
  const history = useHistory();
  const smDown = useMediaQuery(theme.breakpoints.down('md'));

  const isAdmin = isUserAdmin();

  if (group.promised || authProfile.promised) return <Loading />;

  if (!group.data) return null;

  return (
    <div>
      <Title
        breadcrumbs={
          smDown
            ? [
                <IconButton onClick={() => history.goBack()} size="large">
                  <ChevronLeftIcon />
                </IconButton>,
              ]
            : [<Typography variant="body1">Group</Typography>]
        }
      />
      <GroupEdit groupId={group.data && group.data.id} isAuthUserAdmin={isAdmin} />
    </div>
  );
};

export default Group;
