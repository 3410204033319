import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import {LoadingButton} from '@mui/lab';
import {
  Box,
  Button,
  Fade,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import {MouseEvent, useEffect, useState} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {TicketStatus, useNotification, useUserInfo} from '../../..';
import {
  FIELD_NAME_ASSIGNEE,
  FIELD_NAME_ASSOCIATION,
  FIELD_NAME_CATEGORY,
  FIELD_NAME_STATUS,
  FIELD_NAME_SUBJECT,
  TicketStatusNames,
  TicketStatusRules,
} from '../constants/constants';
import {useIsEditingDisabled} from '../hooks/useIsEditingDisabled';
import {useTicketEditContainer} from '../hooks/useTicketEditContainer';
import {useTicketId} from '../hooks/useTicketId';
import {AssigneeEditor} from '../TicketEditDetails/Assignee/AssigneeEditor';
import {useTicketUpdate} from './useTicketUpdate';

export function InputStatus() {
  const history = useHistory();
  const {ticketId} = useTicketId();
  const [statusIsLoading, setStatusIsLoading] = useState(false);
  const {register, unregister, trigger} = useFormContext();
  const status: TicketStatus | undefined = useWatch({name: FIELD_NAME_STATUS});
  const statusOptions: TicketStatus[] = status ? TicketStatusRules[status as TicketStatus] : [];
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const {openEditors} = useTicketEditContainer();
  const {isEditingDisabledStatus, isStatusChangeLocked} = useIsEditingDisabled();
  const hasOpenEditors = Boolean(openEditors.size);
  const {isPortal} = useUserInfo();
  const showNotification = useNotification();

  const {updateStatus} = useTicketUpdate(ticketId);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleStatusChange = async (nextStatus: TicketStatus) => {
    setAnchorEl(null);
    if (!ticketId) return false;
    const isFormValidated = await trigger([
      FIELD_NAME_SUBJECT,
      FIELD_NAME_CATEGORY,
      FIELD_NAME_ASSIGNEE,
      FIELD_NAME_ASSOCIATION,
    ]);
    if (!isFormValidated) return false;

    setStatusIsLoading(true);
    try {
      await updateStatus(nextStatus);
    } catch (error) {
      console.error('Error writing document (InputStatus): ', error);
      return false;
    } finally {
      setStatusIsLoading(false);
    }

    return true;
  };

  const handleClose = () => setAnchorEl(null);

  useEffect(() => {
    register(FIELD_NAME_STATUS);
    return () => unregister(FIELD_NAME_STATUS);
  }, [register]);

  if (status === TicketStatus.Draft) {
    return (
      <Button
        sx={{minWidth: 120}}
        disabled={hasOpenEditors}
        variant="contained"
        onClick={async () => {
          const result = await handleStatusChange(TicketStatus.Open);
          result && history.push('/tickets/view/open');
        }}
      >
        Submit Ticket
      </Button>
    );
  }

  const renderPortalButton = () => (
    <LoadingButton
      loading={statusIsLoading || !status}
      id="fade-button"
      onClick={() => {
        showNotification('info', "You can't edit ticket status");
      }}
      variant="contained"
      sx={{minWidth: 120}}
    >
      {status ? TicketStatusNames[status] : ''}
    </LoadingButton>
  );

  const renderButton = ({
    disabled,
    onClick,
  }: {
    disabled?: boolean;
    onClick: (event: MouseEvent<HTMLElement>) => void;
  }) => (
    <LoadingButton
      disabled={disabled}
      loading={statusIsLoading || !status}
      id="fade-button"
      aria-controls={open ? 'fade-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={onClick}
      sx={{minWidth: 120}}
      variant="contained"
      endIcon={<ArrowDropDownIcon />}
    >
      {status ? TicketStatusNames[status] : ''}
    </LoadingButton>
  );

  return (
    <Box display="flex" alignItems="center">
      <Typography sx={{mr: 2}}>Status:</Typography>

      {isPortal ? (
        renderPortalButton()
      ) : isStatusChangeLocked ? (
        <AssigneeEditor
          buttonComponent={renderButton}
          warningMessage="Please assign the ticket to an individual before updating the status"
        />
      ) : (
        renderButton({disabled: isEditingDisabledStatus, onClick: handleClick})
      )}

      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {statusOptions.map(o => (
          <MenuItem key={o} onClick={() => handleStatusChange(o)}>
            <ListItemIcon>
              <LabelImportantIcon />
            </ListItemIcon>
            <ListItemText> {TicketStatusNames[o]}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
