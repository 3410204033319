import {TicketHistoryRecordType} from '../../..';
import {useTicketHistoryRecord} from './useTicketHistoryRecord';
import {useTicketId} from './useTicketId';
import {useTicketStatus} from './useTicketStatus';

export function useHistoryRecord() {
  const {ticketId} = useTicketId();
  const {isDraft} = useTicketStatus();
  const {addTicketHistoryRecord} = useTicketHistoryRecord();

  const addHistoryRecord = async (
    title: string,
    recordType: TicketHistoryRecordType,
    forceAdd?: boolean,
    titlePortal?: string
  ) => {
    await addTicketHistoryRecord(ticketId, isDraft, title, recordType, forceAdd, titlePortal);
  };

  return {
    addHistoryRecord,
  };
}
