import StorefrontIcon from '@mui/icons-material/Storefront';
import LoadingButton from '@mui/lab/LoadingButton';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import {Application, useCallable, useNotification} from '@ozark/common';
import {useMemo, useState} from 'react';
import {useMerchantApplications} from '../../hooks/useMerchantApplications';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const SelectApplicationDialog = ({open, setOpen}: Props) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [selectedApplication, setSelectedApplication] = useState<Application | null>(null);
  const {merchantApplications} = useMerchantApplications();
  const {getPortalToken} = useCallable();
  const showNotification = useNotification();
  const onCancel = () => {
    setOpen(false);
  };
  const onOk = async () => {
    if (selectedApplication === null) {
      return;
    }
    setIsSaving(true);
    try {
      const result = await getPortalToken({sourceApplicationId: selectedApplication.id});

      if (result.status === 'ok') {
        const a = document.createElement('a');
        a.setAttribute('href', `${process.env.REACT_APP_APP_URL}/portal?token=${result.token}`);
        a.setAttribute('target', '_blank');
        a.click();
        setOpen(false);
        return;
      }
      showNotification('error', 'An error hsa happened. Please try again later.');
    } finally {
      setIsSaving(false);
    }
  };
  const getApplicationInfoToDisplay = (application: Application) => {
    return `${application.doingBusinessAs ?? ''} ${application.mid ?? ''}`;
  };
  const applicationsList = useMemo(() => {
    if (merchantApplications.promised || !merchantApplications.data) {
      return [];
    }
    const applications = merchantApplications.data;
    if (applications.length > 0) {
      const application = applications.find(x => !!x.mid) ?? applications[0];
      setSelectedApplication(application);
    }
    return applications;
  }, [merchantApplications]);
  if (merchantApplications.promised) {
    return null;
  }

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle sx={{textAlign: 'center'}}>Select application to copy</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={applicationsList}
            value={selectedApplication}
            getOptionLabel={application => getApplicationInfoToDisplay(application)}
            disableClearable={selectedApplication !== null}
            onChange={(_event, newInputValue) => {
              setSelectedApplication(newInputValue);
            }}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <StorefrontIcon />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Box>
            <Button onClick={onCancel}>Cancel</Button>
            <LoadingButton onClick={onOk} loading={isSaving} loadingIndicator="Saving…">
              Save
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};
