import {Box, CircularProgress, Paper} from '@mui/material';
import {ResponsiveBar} from '@nivo/bar';
import {DailyVolumeSummary} from '@ozark/functions/src/functions/express/private/types';
import {VolumeFilterTypes} from '@ozark/functions/src/functions/express/private/types/Reports';
import {addDays, format} from 'date-fns';
import {max} from 'lodash';
import {formatUSD} from '../../util';
import {BoxAbs} from '../common';
import {VolumeFilter} from '../Dashboard/FilterPeriodSelect';

const COLOR_BLUE = '#2a7ef0';
const COLOR_AXIS = '#BBBBBB';

const formatRangeDate = (date: string, dateRangeType: VolumeFilterTypes) => {
  const dateToFormat = new Date(date);
  switch (dateRangeType) {
    case VolumeFilterTypes.OneWeek:
    case VolumeFilterTypes.FourWeeks:
    case VolumeFilterTypes.MTD:
      return format(dateToFormat, 'd');
    default:
      return format(addDays(dateToFormat, 1), 'MMM, yy');
  }
};

const formatDataForBarChart = (volumeSummary: DailyVolumeSummary[], filter: VolumeFilter) => {
  const result = volumeSummary
    .filter(x => Number(x.salesCount) > 0)
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .map(row => ({
      date: row.date,
      dateString: formatRangeDate(row.date, filter.type),
      value: Number(row.salesAmount),
      count: Number(row.salesCount),
      empty: 0,
    }));

  return result;
};

const formatLegend = (dateIsoStart: string, dateIsoEnd?: string) => {
  if (!dateIsoStart) return;
  try {
    const dateStartString = format(new Date(dateIsoStart), 'MMM, yyyy');
    if (!dateIsoEnd) return dateStartString;
    const dateEndString = format(new Date(dateIsoEnd), 'MMM, yyyy');
    if (dateStartString === dateEndString) return dateStartString;
    return `${dateStartString} - ${dateEndString}`;
  } catch (error) {
    console.error(error);
  }
};

type Props = {
  volumeData: DailyVolumeSummary[];
  selectedFilter: VolumeFilter;
  loading: boolean;
};

export const VolumeAndCountBarGraph = ({volumeData, selectedFilter, loading}: Props) => {
  const barDatum = formatDataForBarChart(volumeData, selectedFilter);

  const datesAreDays = [
    VolumeFilterTypes.MTD,
    VolumeFilterTypes.OneWeek,
    VolumeFilterTypes.FourWeeks,
  ].includes(selectedFilter.type);
  const daysLegend = datesAreDays
    ? formatLegend(barDatum[0].date, barDatum[barDatum.length - 1].date)
    : undefined;
  return (
    <Box height={372} position="relative" key={selectedFilter.type}>
      {loading && (
        <BoxAbs display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </BoxAbs>
      )}
      {!loading && (
        <BoxAbs>
          <VolumeBarLeftGraph data={barDatum} legend={daysLegend} />
        </BoxAbs>
      )}
      {!loading && (
        <BoxAbs>
          <VolumeBarRightGraph data={barDatum} />
        </BoxAbs>
      )}
    </Box>
  );
};

type PropsGraph = {
  data: ReturnType<typeof formatDataForBarChart>;
  legend?: string;
};

const VolumeBarLeftGraph = ({data, legend}: PropsGraph) => {
  return (
    <ResponsiveBar
      data={data}
      keys={['value', 'empty']}
      indexBy={datum => datum.dateString}
      margin={{
        top: 20,
        right: 96,
        bottom: 40,
        left: 96,
      }}
      padding={0.6}
      groupMode="grouped"
      colors={COLOR_BLUE}
      enableGridX={false}
      enableGridY={false}
      enableLabel={false}
      animate={false}
      renderWrapper={true}
      axisBottom={{
        legendPosition: 'middle',
        legend,
        legendOffset: 34,
        tickSize: 0,
        tickPadding: 10,
      }}
      valueFormat=">-$,~"
      axisLeft={{
        tickSize: 5,
        tickPadding: 25,
        tickRotation: 0,
        legend: 'Volume',
        legendOffset: -90,
        legendPosition: 'middle',
        format: amount => Number(amount).toLocaleString(),
      }}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: COLOR_AXIS,
              strokeWidth: 1,
            },
          },
        },
      }}
    />
  );
};

const VolumeBarRightGraph = ({data}: PropsGraph) => {
  const maxValue = max(data.map(i => i.count)) ?? 0;
  const maxValueThreshhold = Math.trunc(maxValue * 1.25);

  return (
    <ResponsiveBar
      data={data}
      keys={['empty', 'count']}
      indexBy={datum => datum.dateString}
      margin={{
        top: 20,
        right: 96,
        bottom: 40,
        left: 96,
      }}
      padding={0.6}
      groupMode="grouped"
      enableGridX={false}
      enableGridY={false}
      enableLabel={false}
      animate={false}
      renderWrapper={true}
      axisBottom={null}
      axisLeft={null}
      maxValue={maxValueThreshhold}
      axisRight={{
        tickValues: 5,
        tickSize: 5,
        tickPadding: 25,
        tickRotation: 0,
        legend: 'Transactions',
        legendOffset: 70,
        legendPosition: 'middle',
      }}
      tooltip={data => {
        return (
          <Paper sx={{p: 1}} elevation={1}>
            <table>
              <tbody>
                <tr>
                  <td colSpan={3}>
                    <strong>{data.indexValue}</strong>
                  </td>
                </tr>
                <tr>
                  <td width={23}>
                    <Box width={15} height={15} bgcolor={COLOR_BLUE} />
                  </td>
                  <td width={100}>Volume:</td>
                  <td>
                    <strong>{formatUSD(data.data.value)}</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Box width={15} height={15} bgcolor={data.color} />
                  </td>
                  <td>Transactions:</td>
                  <td align="right">
                    <strong>{data.value}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </Paper>
        );
      }}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: COLOR_AXIS,
              strokeWidth: 1,
            },
          },
        },
      }}
    />
  );
};
