const tabsUtilPrefix = '__tabsUtils__';

export const callOnceAcrossTabs = (
  lockKey: string,
  fn: (unlock: () => void) => unknown,
  lockTimeout = 3000
) => {
  const localStorageKey = `${tabsUtilPrefix}${lockKey}`;
  const tabId = performance.now().toString();

  const unlock = () => localStorage.removeItem(localStorageKey);

  try {
    // try to lock tab action
    localStorage.setItem(localStorageKey, tabId);

    // Time to register all locks from other tabs
    setTimeout(() => {
      if (localStorage.getItem(localStorageKey) === tabId) {
        fn(unlock);
      }
    }, 150);

    setTimeout(unlock, lockTimeout);
  } catch (error) {
    unlock();
    throw error;
  }
};
