import {
  MERCHANTS_PORTFOLIO as MERCHANTS_PORTFOLIO_ROUTE,
  MERCHANT_PORTFOLIO_DETAILS as MERCHANT_PORTFOLIO_DETAILS_ROUTE,
  MERCHANT_PORTFOLIO_DETAILS_TAB as MERCHANT_PORTFOLIO_DETAILS_TAB_ROUTE,
} from '@ozark/common/components';
import {
  APPLICATIONS_ANALYTICS_DETAILS_ROUTE,
  MERCHANTS_ROUTE,
  MERCHANT_DETAILS_ROUTE,
  MFA_AUTH_ROUTE,
  PORTFOLIO_PCI as PORTFOLIO_PCI_ROUTE,
} from '@ozark/common/constants/routes';

export const _1099K = '/1099k';
export const ACCOUNT = '/account';
export const ACCOUNT_UPDATES = `${ACCOUNT}/updates`;
export const ACTIVATIONS = '/activations';
export const AGENT = `/agents/:id`;
export const AGENTS = `/agents`;
export const ANALYTICS = '/analytics';
export const ANALYTICS_PORTFOLIO = `${ANALYTICS}/portfolio`;
export const ANALYTICS_VOLUME_DETAIL = `${ANALYTICS}/volume-detail`;
export const ANALYTICS_YEARLY_SUMMARY = `${ANALYTICS}/yearly-summary`;
export const ANALYTICS_AUTHORIZATION_DETAIL = `${ANALYTICS}/authorization-detail`;
export const ANALYTICS_AGENT_MID_ASSIGNMENT = `${ANALYTICS}/agent-mid-assignment`;
export const ANALYTICS_MCC_BY_SALES = `${ANALYTICS}/mcc-by-transactions`;
export const ANALYTICS_MIDS_BY_PROCESSING = `${ANALYTICS}/mids-by-sales`;
export const ANALYTICS_MIDS_BY_CHARGEBACKS = `${ANALYTICS}/mids-by-chargebacks`;
export const ANALYTICS_MIDS_BY_CHARGEBACKS_DETAILS = `${ANALYTICS_MIDS_BY_CHARGEBACKS}/:mid`;
export const ANALYTICS_ESTIMATED_VS_ACTUAL_VOLUME = `${ANALYTICS}/estimated-vs-actual-volume`;
export const APPLICATION_PATH = '/application';
export const APPLICATION = `${APPLICATION_PATH}/:id`;
export const APPLICATIONS = `/applications`;
export const APPLICATIONS_ANALYTICS_DETAILS = APPLICATIONS_ANALYTICS_DETAILS_ROUTE;
export const APPLICATIONS_STATUSES = `/applications-statuses`;
export const APPLICATIONS_ALL = `${APPLICATIONS}/all`;
export const APPLICATIONS_CONDITIONALLY_APPROVED = `${APPLICATIONS}/conditionally-approved`;
export const APPLICATIONS_BOARDED = `${APPLICATIONS}/boarded`;
export const APPLICATIONS_INCOMPLETE = `${APPLICATIONS}/incomplete`;
export const APPLICATIONS_AS = `${APPLICATIONS}/agent-support`;
export const APPLICATIONS_START_NEW_APP = `${APPLICATIONS}/start-new-app`;
export const APPLICATIONS_UNDERWRITING = `${APPLICATIONS}/underwriting`;
export const AUTHORIZATIONS = '/authorizations';
export const DASHBOARD = '/';
export const DEPOSITS = '/deposits';
export const DISPUTES = `/disputes`;
export const EMAIL_LOG = '/email-log';
export const FORGOT_PASSWORD = `/forgot-password`;
export const GROUP = '/group';
export const MERCHANTS_PORTFOLIO = MERCHANTS_PORTFOLIO_ROUTE;
export const MERCHANT_PORTFOLIO_DETAILS = MERCHANT_PORTFOLIO_DETAILS_ROUTE;
export const MERCHANT_PORTFOLIO_DETAILS_TAB = MERCHANT_PORTFOLIO_DETAILS_TAB_ROUTE;
export const LOGIN = '/login';
export const MERCHANT_REGISTRATION = '/merchant-registration';
export const MERCHANTS = MERCHANTS_ROUTE;
export const MERCHANT_DETAILS = MERCHANT_DETAILS_ROUTE;
export const MERCHANT_DETAILS_TAB = `${MERCHANT_DETAILS}/:tabValue`;
export const ONLINE = '/online-app';
export const ONLINE_NEW_APP = `${ONLINE}/new-app`;
export const ONLINE_PRICING = `/pricing`;
export const APP_URL = `/app-url`;
export const PCI = '/pci';
export const PORTFOLIO = '/portfolio';
export const PORTFOLIO_ACH_REJECTS = `${PORTFOLIO}/ach-rejects`;
export const PORTFOLIO_NON_PROCESSING = `${PORTFOLIO}/non-processing`;
export const PORTFOLIO_PCI = PORTFOLIO_PCI_ROUTE;
export const PORTFOLIO_RESERVES = `${PORTFOLIO}/reserves`;
export const PORTFOLIO_TIN = `${PORTFOLIO}/tin`;
export const PROFILE = '/profile';
export const REFERRAL = '/refer-a-friend';
export const REPORTS = '/reports';
export const REPORTS_ANALYTICS = `${REPORTS}/analytics`;
export const RESET_PASSWORD = '/reset-password';
export const RESIDUALS = '/residuals';
export const RESIDUALS_GROUP = '/residuals/group';
export const RESIDUALS_AGENT = '/residuals/agent';
export const RESIDUALS_OVERVIEW = '/residuals/overview';
export const RESOURCES = `/resources`;
export const STATEMENTS = '/statements';
export const TICKETS = '/tickets';
export const BATCHES = '/batches';
export const CHARGEBACKS = '/chargebacks';
export const CHARGEBACKS_SUMMARY = '/chargebacks-summary';
export const CHARGEBACKS_DETAILS = '/chargeback-details';
export const TRANSACTIONS = '/transactions';
export const TRANSACTIONS_MID = '/transactions/:mid';
export const TRANSACTIONS_All = `${TRANSACTIONS}/all`;
export const TRANSACTIONS_PENDING = `${TRANSACTIONS}/pending`;
export const TRANSACTIONS_SEARCH = `${TRANSACTIONS}/search`;
export const FRAUD_ANALYSIS = `/fraud-analysis`;
export const AGENT_STATISTICS = `/agent-statistics`;
export const DOCUMENTS_UPLOAD = '/documents-upload';
export const MFA_AUTH = MFA_AUTH_ROUTE;
