import {useCallback} from 'react';
import {Collections, Firebase, TicketHistoryRecord, TicketHistoryRecordType} from '../../..';
import {useUserInfo} from '../../../hooks';
import {getMerchantNameAsync} from '../../../hooks/useMerchant';

export const useTicketHistoryRecord = () => {
  const {uid, userDisplayName, isMerchant, role, department} = useUserInfo();

  const addTicketHistoryRecord = useCallback(
    async (
      ticketId: string,
      isDraftTicket: boolean,
      title: string,
      recordType: TicketHistoryRecordType,
      forceAdd?: boolean,
      titlePortal?: string
    ) => {
      const disabled = isDraftTicket && !forceAdd;
      if (disabled || !uid || !ticketId) return;

      const userName = isMerchant ? await getMerchantNameAsync(uid) : userDisplayName;

      addHistoryRecordDirect(ticketId, {
        uid: uid ?? 'n/a',
        userName: userName ?? 'n/a',
        userRole: role ?? 'n/a',
        department: department,
        title,
        createdAt: Firebase.FieldValue.now(),
        recordType,
        titlePortal,
      });
    },
    [uid, userDisplayName, isMerchant]
  );

  return {
    addTicketHistoryRecord,
  };
};

export const addHistoryRecordDirect = async (
  ticketId: string,
  ticketHistoryRecord: TicketHistoryRecord
) => {
  await Firebase.firestore
    .collection(Collections.tickets)
    .doc(ticketId)
    .collection(Collections.history)
    .add(ticketHistoryRecord);
};
