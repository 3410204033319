import {Box, Typography} from '@mui/material';
import {AllMIDs, useApiContainer, useMidsContainer, VolumeResult} from '@ozark/common';
import {VolumeSummaryByCardTypePieChart} from '@ozark/common/components/Analytics/VolumeSummary/VolumeSummaryByCardTypePieChart';
import {useEffect, useState} from 'react';
import {VolumeAndCountBarGraph} from '../Analytics/Volume2AxisGraph';
import {DashboardCell, DashboardGrid} from './common/DashboardCell';
import {VolumeFilter} from './FilterPeriodSelect';

type Props = {
  filters: VolumeFilter;
};

const TransactionsSummary = ({filters}: Props) => {
  const [volumeReport, setVolumeReport] = useState<VolumeResult>();
  const [loading, setLoading] = useState(true);
  const {selectedMid} = useMidsContainer();
  const api = useApiContainer();

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const volumeReportResult = await api?.reports.getVolumeDetail(
          filters,
          selectedMid === AllMIDs ? undefined : selectedMid
        );

        setVolumeReport(volumeReportResult);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [selectedMid, filters, api]);

  return (
    <Box>
      <Typography variant="h5" gutterBottom color="secondary.main" fontSize="1.7rem">
        Processing Analytics
      </Typography>
      {volumeReport?.hasData ? (
        <DashboardGrid>
          <DashboardCell title="Sales" width={7}>
            <VolumeAndCountBarGraph
              volumeData={volumeReport.volumeSummary}
              selectedFilter={filters}
              loading={loading}
            />
          </DashboardCell>
          <DashboardCell title="Summary by Card Type" width={5}>
            <VolumeSummaryByCardTypePieChart summaryByCardType={volumeReport.summaryByCardType} />
          </DashboardCell>
        </DashboardGrid>
      ) : (
        <Box mt={2}>
          <Typography>Transactions not found</Typography>
        </Box>
      )}
    </Box>
  );
};

export default TransactionsSummary;
