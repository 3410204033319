import {DocumentBase} from '.';
import {Overwrite} from '../helpers';
import {UniversalTimestamp} from './compat';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export type ResourceDocumentCategory = DocumentBase & {
  name: string;
  createdAt: UniversalTimestamp;
  deleted: boolean;
};

export type ResourceDocumentCategoryInput = Overwrite<ResourceDocumentCategory, {createdAt: Date}>;

export type ResourceDocumentCategoryView = ViewBase<ResourceDocumentCategory>;

export const selectResourceDocumentCategoryView: ViewSelector<
  ResourceDocumentCategoryView,
  ResourceDocumentCategory
> = snapshot => {
  const resourceDocumentCategory: ResourceDocumentCategoryView = {
    ...defaultViewSelector(snapshot),
  };
  return resourceDocumentCategory;
};
