import {yupResolver} from '@hookform/resolvers/yup';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import {Theme, useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {AgentStatus, GroupRole, useCallable, useNotification} from '@ozark/common';
import {emptyStringToNull} from '@ozark/common/helpers';
import PhoneNumber from 'awesome-phonenumber';
import firebase from 'firebase/compat/app';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {useStore} from '../../store/helpers';
import {AgentForm} from './AgentForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      [theme.breakpoints.up('md')]: {
        width: 600,
      },
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

const schema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().required('Email is required'),
  role: yup.string().required('Role is required'),
  phone: yup
    .string()
    .min(12, 'Must be a valid phone number') // validating against e164 format (+18002333333)
    .transform(value => {
      const phone = value ? new PhoneNumber(value, 'US').getNumber('e164') : value;
      return phone;
    })
    .required('Phone is required'),
  dateOfBirth: yup.date().nullable().transform(emptyStringToNull).typeError('Invalid date'),
});

export const CreateDialog = ({
  onClose,
  onAgentCreated,
}: {
  onClose: () => void;
  onAgentCreated: () => void;
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const {createAgent} = useCallable();
  const {isUserAdmin} = useStore();

  const [loading, setLoading] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const showNotification = useNotification();
  const isAdmin = isUserAdmin();

  const hookForm = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      role: GroupRole.member,
      status: AgentStatus.active,
    } as any,
    shouldUnregister: true,
  });
  const {handleSubmit} = hookForm;

  const onSuccess = async (data: any) => {
    const {groupId, dateOfBirth, ...dataWithoutGroupId} = data;

    try {
      setLoading(true);

      const result = await createAgent({
        ...dataWithoutGroupId,
        dateOfBirth: dateOfBirth && firebase.firestore.Timestamp.fromDate(dateOfBirth),
      });

      if (result.status === 'ok') {
        showNotification('success', 'User successfully created.');
        setLoading(false);
        onAgentCreated();
        return;
      }

      throw Error(result.message);
    } catch (err) {
      showNotification('error', (err as Error).message);
      setLoading(false);
    }
  };

  const onError = (_data: any) => {
    setLoading(false);
  };

  return (
    <Dialog
      open={true}
      aria-labelledby="create-dialog-title"
      fullScreen={fullScreen}
      maxWidth={'lg'}
    >
      <DialogTitle id="create-dialog-title">Create Agent</DialogTitle>
      <DialogContent>
        <div className={classes.content}>
          <Grid container spacing={1}>
            <AgentForm hookForm={hookForm} isAuthUserAdmin={isAdmin} editMode={false} />
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit(onSuccess, onError)} color="primary" disabled={loading}>
          {loading && <CircularProgress className={classes.buttonProgress} size={24} />}
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
