import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  AgentView,
  CallableFunctionName,
  ExportToCsv,
  Firebase,
  PublishedResidualOptionsResults,
  SearchCriteria,
  useAgents,
  useApiContainer,
  useCallable,
} from '@ozark/common';
import {Column} from '@ozark/common/api/Column';
import {
  ActiveFilter,
  AutoCompleteInputBase,
  FilterOption,
  Filters,
  Loading,
  Square,
  Table as DataTable,
  Title,
} from '@ozark/common/components';
import {PaginatedResponse} from '@ozark/functions/src/functions/express/private/types/PaginatedResponse';
import {AgentResidual} from '@ozark/functions/src/functions/express/private/types/Residual';
import {groupBy} from '@s-libs/micro-dash';
import {parse} from 'date-fns';
import {format} from 'date-fns-tz';
import {isEmpty} from 'lodash';
import startcase from 'lodash/startCase';
import {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {currentFormatter, forceActiveFilter, useReportingPageStyles} from '../../reports';

const ResidualsFilters: FilterOption[] = [
  {
    id: 'mid',
    column: 'mid',
    label: 'MID',
    type: 'text',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'dba',
    column: 'dba',
    label: 'DBA',
    type: 'text',
    operators: [
      {
        id: '__like',
        label: 'contains',
      },
    ],
  },
  {
    id: 'agentName',
    column: 'agentName',
    label: 'Agent Name',
    type: 'text',
    operators: [
      {
        id: '__like',
        label: 'contains',
      },
    ],
  },
  {
    id: 'yearMonth',
    column: 'yearMonth',
    label: 'Date',
    type: 'text',
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
];

export const DefaultCriteria: SearchCriteria = {
  limit: 10, // page size
  offset: 1, // page
  order: 'desc',
  orderBy: 'yearMonth',
};

interface AgentPayoutsToDisplay {
  agentName: string;
  description: string;
  amount: number;
}

type Props = {
  showActiveAgents: boolean;
  authorizedAgents?: AgentView[];
  masterAgentUid?: string;
};

export const AgentResiduals = ({
  showActiveAgents,
  authorizedAgents,
  masterAgentUid,
}: Props): JSX.Element => {
  const classes = useReportingPageStyles();
  const api = useApiContainer();
  const [activeAgents, setActiveAgents] = useState<AgentView[]>([]);
  const {documents: agents} = useAgents();
  const [residuals, setResiduals] = useState<
    | (PaginatedResponse<AgentResidual> & {
        bankcardSalesVolume: string;
        totalIncome: string;
        agentExpense: string;
        agentNet: string;
        agentNetSplit: string;
        subAgentExpense: string;
        subAgentNet: string;
        subAgentNetSplit: string;
        agentProfit: string;
        transactionCount: string;
      })
    | null
  >();
  const [loadingResiduals, setLoadingResiduals] = useState(true);
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>(DefaultCriteria);
  const [filters, setFilters] = useState<{[_: string]: ActiveFilter}>({});
  const [yearMonths, setYearMonths] = useState<string[] | null>(null);
  const [selectedYearMonth, setSelectedYearMonth] = useState<string>();
  const [selectedAgent, setSelectedAgent] = useState<AgentView>();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [agentPayouts, setAgentPayouts] = useState<AgentPayoutsToDisplay[] | null>(null);
  const [payoutAdj, setPayoutAdj] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<any>(0);
  const {getResidualAdjustments} = useCallable();
  useEffect(() => {
    if (!selectedYearMonth) {
      setAgentPayouts(null);
      return;
    }

    getResidualAdjustments({
      yearMonth: selectedYearMonth,
      agentId: selectedAgent?.id,
    }).then(result => {
      if (result.status === 'ok') {
        setAgentPayouts(result.data);
      }
    });
  }, [yearMonths, selectedAgent?.id, selectedYearMonth]);

  useEffect(() => {
    if (!agentPayouts) {
      setPayoutAdj(0);
      return;
    }

    const nextPayoutAdj = agentPayouts.reduce(
      (previousValue, current) => previousValue + current.amount,
      0
    );

    setPayoutAdj(nextPayoutAdj);
  }, [agentPayouts]);

  useEffect(() => {
    (async () => {
      const getPublishedResidualOptions = Firebase.functions.httpsCallable(
        CallableFunctionName.getPublishedResidualOptions
      );
      const result: {data: PublishedResidualOptionsResults} = await getPublishedResidualOptions({});
      if (!result?.data?.options) {
        setLoadingResiduals(false);
        return;
      }

      const options = result.data.options.sort();
      setSelectedYearMonth(options[options.length - 1]);
      setYearMonths(options);
      if (!options.length) {
        setLoadingResiduals(false);
      }

      const forcedFilter = forceActiveFilter(
        ResidualsFilters,
        'yearMonth',
        '__eq',
        options[options.length - 1]
      );
      setFilters({...filters, yearMonth: forcedFilter});
    })();
    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    if (loadingResiduals) return;
    if (ref?.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [loadingResiduals]);

  const getFullName = (agent: AgentView) =>
    `${agent.firstName || ''} ${agent.lastName || ''}`.trim();

  const agentNameComparer = (a: AgentView, b: AgentView) =>
    getFullName(a).localeCompare(getFullName(b));

  useEffect(() => {
    if (!agents.data) {
      return;
    }

    setActiveAgents(agents.data.filter(agent => agent.isActive));
  }, [agents]);

  const getResiduals = () => {
    setLoadingResiduals(true);

    api?.residuals
      .getAgentResiduals(searchCriteria, Object.values(filters), selectedAgent?.id)
      .then((result: any) => {
        setResiduals(result || {});
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoadingResiduals(false);
      });
  };

  const exportToCsv = async () => {
    if (!api) return;

    const options = {
      fieldSeparator: ',',
      filename: `residuals-${selectedYearMonth}`,
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Residuals',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const exportData = await api.residuals.getAgentResidualsExport(
      searchCriteria,
      Object.values(filters)
    );

    if (!exportData) return;

    const exporter = new ExportToCsv(options);

    const noNullData = JSON.parse(JSON.stringify(exportData).replace(/null/gi, '""'));

    exporter.generateCsv(noNullData);
  };

  useEffect(() => {
    if (!selectedYearMonth) return;
    getResiduals();
    // eslint-disable-next-line
  }, [searchCriteria, filters, selectedAgent]);

  const handleRetrieveData = (searchCriteria: SearchCriteria) => {
    setSearchCriteria(searchCriteria);
  };

  const handleDateChange = (event: any) => {
    const yearMonth = event.target.value;
    setSelectedYearMonth(yearMonth);
    const forcedFilter = forceActiveFilter(ResidualsFilters, 'yearMonth', '__eq', yearMonth);
    setFilters({...filters, yearMonth: forcedFilter});
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleApplyFilter = (filter: ActiveFilter) => {
    setFilters({...filters, [filter.option.column]: filter});
  };

  const handleDeleteFilter = (id: string) => () => {
    const _filters = {...filters};
    delete _filters[id];
    setFilters(_filters);
  };

  const formatYearMonth = (yearMonth: string) => {
    const date = parse(yearMonth as string, 'yyyyMM', new Date());
    return format(date, 'MMMM, yyyy');
  };

  const getBreadcrumbs = () => {
    return [<Typography variant="body1">Agent Residuals</Typography>];
  };

  const getDropDownTitle = (agent?: AgentView): string => {
    return agent ? `${agent?.firstName} ${agent?.lastName}` : 'All Agents';
  };

  if (loadingResiduals) {
    return <Loading />;
  }

  if (!yearMonths || yearMonths.length === 0) {
    return (
      <Box mt={6}>
        <Typography variant="body1" align="center">
          No residuals have been added.
        </Typography>
      </Box>
    );
  }

  return (
    <div className={classes.root} ref={ref}>
      <Title breadcrumbs={getBreadcrumbs()}>
        <div className={classes.grow} />

        <AutoCompleteInputBase
          selected={selectedAgent}
          setSelected={setSelectedAgent}
          icon
          placeholder="Select agent..."
          options={(showActiveAgents ? activeAgents : authorizedAgents) ?? []}
          comparer={agentNameComparer}
          getOptionLabel={(agent: AgentView) => getDropDownTitle(agent)}
          onItemSelect={(agent: AgentView | null) => setSelectedAgent(agent ?? undefined)}
        />

        <Divider orientation="vertical" className={classes.divider} flexItem />

        {yearMonths && (
          <TextField
            value={selectedYearMonth}
            onChange={handleDateChange}
            variant="standard"
            InputProps={{
              classes: {
                input: classes.selectInput,
              },
              disableUnderline: true,
            }}
            select
          >
            {yearMonths.map(e => {
              return (
                <MenuItem key={e} value={e}>
                  {formatYearMonth(e)}
                </MenuItem>
              );
            })}
          </TextField>
        )}
        <Divider orientation="vertical" className={classes.divider} flexItem />
        <Filters options={ResidualsFilters} onApplyFilter={handleApplyFilter} />
        <Divider orientation="vertical" className={classes.divider} flexItem />
        <Button onClick={exportToCsv}>Export</Button>
      </Title>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{
          paper: classes.menuPaper,
        }}
      />

      {residuals && isEmpty(residuals) && (
        <Typography className={classes.noContent}>No Residuals</Typography>
      )}

      {residuals && !isEmpty(residuals) && (
        <>
          <Grid container spacing={2} direction="row" alignItems="stretch">
            <Grid item xs={12}>
              {filters && !isEmpty(filters) && (
                <IconButton disabled size="large">
                  <FilterListIcon />
                </IconButton>
              )}

              {filters &&
                Object.keys(filters).map(key => {
                  const filter = filters[key];

                  if (filter.option.type === 'dateRange' && filter.operator.id === '__between') {
                    return (
                      <Chip
                        key={`${key}-${filter.operator.id}`}
                        className={classes.chip}
                        label={
                          <span>
                            <b>{filter.option.label}</b> {filter.operator.label} '
                            <b>{format(filter.value?.[0] as Date, 'MM/dd/yyyy h:mm a')}</b>' and '
                            <b>{format(filter.value?.[1] as Date, 'MM/dd/yyyy h:mm a')}</b>'
                          </span>
                        }
                        variant="outlined"
                        onDelete={handleDeleteFilter(key)}
                      />
                    );
                  }

                  if (filter.option.type === 'date' && filter.operator.id === '__between') {
                    return (
                      <Chip
                        key={`${key}-${filter.operator.id}`}
                        className={classes.chip}
                        label={
                          <span>
                            <b>{filter.option.label}</b> {filter.operator.label} '
                            <b>{format(filter.value?.[0] as Date, 'MM/dd/yyyy')}</b>'
                          </span>
                        }
                        variant="outlined"
                        onDelete={handleDeleteFilter(key)}
                      />
                    );
                  }

                  if (filter.option.id === 'yearMonth') {
                    return (
                      <Chip
                        key={`${key}-${filter.operator.id}`}
                        className={classes.chip}
                        label={
                          <span>
                            <b>{filter.option.label}</b> {filter.operator.label} '
                            <b>{formatYearMonth(filter.value as string)}</b>'
                          </span>
                        }
                        variant="outlined"
                        onDelete={filter.option.force ? undefined : handleDeleteFilter(key)}
                      />
                    );
                  }

                  return (
                    <Chip
                      key={`${key}-${filter.operator.id}`}
                      className={classes.chip}
                      label={
                        <span>
                          <b>{filter.option.label}</b> {filter.operator.label} '
                          <b>
                            {filter.option.type === 'currency'
                              ? `$${filter.value}`
                              : `${filter.value}`}
                          </b>
                          '
                        </span>
                      }
                      variant="outlined"
                      onDelete={filter.option.force ? undefined : handleDeleteFilter(key)}
                    />
                  );
                })}
            </Grid>

            <Grid item xs={3}>
              <Square
                center
                lines={{
                  'Total Transactions': parseFloat(residuals?.transactionCount || '0'),
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <Square
                center
                lines={{
                  'Total Sales Volume': currentFormatter.format(
                    parseFloat(residuals?.bankcardSalesVolume || '0')
                  ),
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <Square
                center
                lines={{
                  'Total Profit': currentFormatter.format(parseFloat(residuals.agentNet || '0')),
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <Square
                center
                lines={{
                  'Residual Paid': currentFormatter.format(
                    parseFloat(residuals.agentNetSplit || '0') + payoutAdj
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Box
            component={Paper}
            overflow="auto"
            mt={2}
            sx={[{'& > div:first-child': {width: '100%'}}, {width: {width}}]}
          >
            <DataTable
              noWrap={true}
              columns={
                [
                  {
                    id: 'yearMonth',
                    numeric: false,
                    sortable: true,
                    export: true,
                    label: 'Date',
                    selector: row => {
                      return formatYearMonth(row.yearMonth);
                    },
                  },
                  {
                    id: 'mid',
                    numeric: false,
                    sortable: true,
                    label: 'Merchant ID',
                  },
                  {
                    id: 'agentName',
                    numeric: false,
                    sortable: true,
                    label: 'Agent Name',
                  },
                  {
                    id: 'dba',
                    numeric: false,
                    sortable: true,
                    label: 'DBA',
                  },
                  {
                    id: 'riskLevel',
                    numeric: false,
                    sortable: true,
                    label: 'Risk Level',
                    selector: row => startcase(row.riskLevel),
                  },
                  {
                    id: 'transactionCount',
                    numeric: true,
                    sortable: true,
                    label: 'Transaction Count',
                  },
                  {
                    id: 'bankcardSalesVolume',
                    numeric: true,
                    sortable: true,
                    label: 'Sales Volume',
                    selector: row => currentFormatter.format(row.bankcardSalesVolume),
                  },
                  {
                    id: 'totalIncome',
                    numeric: true,
                    sortable: true,
                    label: 'Merchant Income',
                    selector: row => currentFormatter.format(row.totalIncome),
                  },
                  ...(masterAgentUid
                    ? [
                        {
                          id: 'subAgentExpense',
                          numeric: true,
                          sortable: true,
                          label: 'Agent Expense',
                          selector: (row: AgentResidual) =>
                            row.subAgentExpense ? currentFormatter.format(row.subAgentExpense) : '',
                        },
                        {
                          id: 'subAgentNet',
                          numeric: true,
                          sortable: true,
                          label: 'Agent Net',
                          selector: (row: AgentResidual) =>
                            row.subAgentNet ? currentFormatter.format(row.subAgentNet) : '',
                        },
                        {
                          id: 'subAgentSplitPercent',
                          numeric: true,
                          sortable: true,
                          label: 'Agent Split',
                          selector: (row: AgentResidual) =>
                            row.subAgentSplitPercent ? `${row.subAgentSplitPercent}%` : '',
                        },
                        {
                          id: 'subAgentNetSplit',
                          numeric: true,
                          sortable: true,
                          label: 'Profit',
                          selector: (row: AgentResidual) =>
                            row.subAgentNetSplit
                              ? currentFormatter.format(row.subAgentNetSplit)
                              : '',
                        },
                      ]
                    : [
                        {
                          id: 'agentExpense',
                          numeric: true,
                          sortable: true,
                          label: 'Agent Expense',
                          selector: (row: AgentResidual) =>
                            row.agentExpense ? currentFormatter.format(row.agentExpense) : '',
                        },
                        {
                          id: 'agentNet',
                          numeric: true,
                          sortable: true,
                          label: 'Agent Net',
                          selector: (row: AgentResidual) =>
                            row.agentNet ? currentFormatter.format(row.agentNet) : '',
                        },
                        {
                          id: 'agentSplitPercent',
                          numeric: true,
                          sortable: true,
                          label: 'Agent Split',
                          selector: (row: AgentResidual) =>
                            row.agentSplitPercent ? `${row.agentSplitPercent}%` : '',
                        },
                        {
                          id: 'agentNetSplit',
                          numeric: true,
                          sortable: true,
                          label: 'Agent Profit',
                          selector: (row: AgentResidual) =>
                            row.agentNetSplit ? currentFormatter.format(row.agentNetSplit) : '',
                        },
                        {
                          id: 'subAgentExpense',
                          numeric: true,
                          sortable: true,
                          label: 'Sub Agent Expense',
                          selector: (row: AgentResidual) =>
                            row.subAgentExpense ? currentFormatter.format(row.subAgentExpense) : '',
                        },
                        {
                          id: 'subAgentNet',
                          numeric: true,
                          sortable: true,
                          label: 'Sub Agent Net',
                          selector: (row: AgentResidual) =>
                            row.subAgentNet ? currentFormatter.format(row.subAgentNet) : '',
                        },
                        {
                          id: 'subAgentSplitPercent',
                          numeric: true,
                          sortable: true,
                          label: 'Sub Agent Split',
                          selector: (row: AgentResidual) =>
                            row.subAgentSplitPercent ? `${row.subAgentSplitPercent}%` : '',
                        },
                        {
                          id: 'subAgentNetSplit',
                          numeric: true,
                          sortable: true,
                          label: 'Sub Agent Profit',
                          selector: (row: AgentResidual) =>
                            row.subAgentNetSplit
                              ? currentFormatter.format(row.subAgentNetSplit)
                              : '',
                        },
                        {
                          id: 'agentProfit',
                          numeric: true,
                          sortable: true,
                          label: 'Profit',
                          selector: (row: AgentResidual) =>
                            row.agentProfit ? currentFormatter.format(row.agentProfit) : '',
                        },
                      ]),
                ] as Column<AgentResidual>[]
              }
              data={residuals}
              summary={{
                bankcardSalesVolume: currentFormatter.format(
                  parseFloat(residuals?.bankcardSalesVolume || '0')
                ),
                transactionCount: parseFloat(residuals?.transactionCount || '0'),
                totalIncome: currentFormatter.format(parseFloat(residuals?.totalIncome || '0')),
                agentExpense: currentFormatter.format(parseFloat(residuals?.agentExpense || '0')),
                agentNet: currentFormatter.format(parseFloat(residuals?.agentNet || '0')),
                agentNetSplit: currentFormatter.format(parseFloat(residuals?.agentNetSplit || '0')),
                subAgentExpense: currentFormatter.format(
                  parseFloat(residuals?.subAgentExpense || '0')
                ),
                subAgentNet: currentFormatter.format(parseFloat(residuals?.subAgentNet || '0')),
                subAgentNetSplit: currentFormatter.format(
                  parseFloat(residuals?.subAgentNetSplit || '0')
                ),
                agentProfit: currentFormatter.format(parseFloat(residuals?.agentProfit || '0')),
              }}
              onRowClick={() => {}}
              onRetrieveData={handleRetrieveData}
              paginate
              stickyHeader
              scrollableBody
              customHeight="75vh"
            />

            {!!agentPayouts && (
              <Box mt={2} p={2}>
                <Typography variant="h5">Adjustments</Typography>

                <TableContainer component={Paper}>
                  <Table sx={{maxWidth: 650}}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Agent</TableCell>
                        <TableCell align="right">Description</TableCell>
                        <TableCell align="right">Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(
                        groupBy(agentPayouts, agentPayout => agentPayout.agentName)
                      ).map(([agentName, payouts], index) =>
                        payouts.map(({amount, description}, idx) => (
                          <TableRow
                            key={`${agentName}-${index}-${idx}`}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                          >
                            <TableCell component="th" scope="row">
                              {agentName}
                            </TableCell>

                            <TableCell align="right">{description}</TableCell>

                            <TableCell align="right" sx={{color: amount >= 0 ? 'green' : 'red'}}>
                              {currentFormatter.format(amount)}
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                      <TableRow key="summary">
                        <TableCell align="right" colSpan={2} sx={{fontWeight: 500}}>
                          Total
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{color: payoutAdj >= 0 ? 'green' : 'red', fontWeight: 500}}
                        >
                          {currentFormatter.format(payoutAdj)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
          </Box>
        </>
      )}
    </div>
  );
};
