import {Dispositions, TransferStatus} from '@ozark/common';
import {FilterOption} from '@ozark/common/components';
import {ApplicationResponse} from '@ozark/functions/src/functions/express/private/types';

export type ApplicationListData = {
  items: ApplicationResponse[];
  hasNextPage: boolean;
  totalCount?: number;
};

export const ApplicationFilters: FilterOption[] = [
  {
    id: 'uid',
    column: 'uid',
    label: 'uid',
    type: 'text',
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'groupId',
    column: 'groupId',
    label: 'Group',
    type: 'text',
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'agentId',
    column: 'agentId',
    label: 'Agent',
    type: 'text',
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'deleted',
    column: 'deleted',
    label: 'deleted',
    type: 'boolean',
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'dispositions',
    column: 'disposition',
    label: 'Dispositions',
    type: 'list',
    options: Object.values(Dispositions).map(x => ({key: x, value: x})),
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'associatedAgents',
    column: 'associatedAgents',
    label: 'Associated Agents',
    type: 'text',
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'contains',
      },
    ],
  },
  {
    id: 'associatedMerchants',
    column: 'associatedMerchants',
    label: 'Associated Merchants',
    type: 'text',
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'contains',
      },
    ],
  },
];

export const UwApplicationFilters: FilterOption[] = [
  {
    id: 'uid',
    column: 'uid',
    label: 'uid',
    type: 'text',
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'deleted',
    column: 'deleted',
    label: 'deleted',
    type: 'boolean',
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'dispositions',
    column: 'disposition',
    label: 'Dispositions',
    type: 'list',
    options: Object.values(Dispositions).map(x => ({key: x, value: x})),
    force: true,
    operators: [
      {
        id: '__eq', // we use __in because of this https://github.com/slaveofcode/restful-filter/blob/master/src/filters/in.js we are losing spaces
        label: 'equals',
      },
    ],
  },
  {
    id: 'groupId',
    column: 'groupId',
    label: 'Group',
    type: 'text',
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'agentId',
    column: 'agentId',
    label: 'Agent',
    type: 'text',
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'associatedAgents',
    column: 'associatedAgents',
    label: 'Associated Agents',
    type: 'text',
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'contains',
      },
    ],
  },
];

export const IncompleteApplicationFilters: FilterOption[] = [
  {
    id: 'uid',
    column: 'uid',
    label: 'uid',
    type: 'text',
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'groupId',
    column: 'groupId',
    label: 'Group',
    type: 'text',
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'agentId',
    column: 'agentId',
    label: 'Agent',
    type: 'text',
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'deleted',
    column: 'deleted',
    label: 'deleted',
    type: 'boolean',
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'transferStatus',
    column: 'transferStatus',
    label: 'Incomplete Status',
    type: 'list',
    options: Object.values(['All Incomplete', ...Object.values(TransferStatus)]).map(x => ({
      key: x,
      value: x,
    })),
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
];
